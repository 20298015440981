.stripe{
  .logo-wrapper{
    padding: 30px;
    text-align: center;
  }
  .wrapper-post{
    padding: 0 30px;
    text-align: center;
  }
  .result-message{
    width: 100%;
    padding: 5px 15px;
    color: #79ae3e;
    line-height: 1.733;
    font-weight: bold;
    text-align: center;
    background-color: #e2edd5;
  }
  #stripe-payment-form {
    padding: 0 30px;
    margin: 30px auto;
    max-width: 720px;
    width: 100%;
    text-align: center;

    #card-element {
      border: 1px solid var(--color-primary);
      border-radius: 15px;
      margin: 20px 0;
      padding: 20px;
    }

    .result-message,
    .alert-message {
      display: block;
      text-align: center;

      &.hidden {
        display: none;
      }
    }

    .ElementsApp input {
      border: 1px solid var(--color-primary);
    }

    #card-error {
      width: 100%;
      padding: 5px 15px;
      color: #e62e2e;
      line-height: 1.733;
      font-weight: bold;
      text-align: center;
      background-color: #ffbfbf;
      &.hidden{
        display: none;
      }
    }

  }
}
