.slider-team {
  border-bottom: 1px solid $color-border;
  margin: 70px 0;
  text-align: center;

  & + .block-text-on-bg {
    margin-top: -70px;
  }
  
  .section-title {
    margin-bottom: 20px;
  }

  .description.below-title {
    margin-top: 20px;
  }

  &-wrapper {
    padding: 40px 0 160px 0;
    position: relative;
  }

  &-inner {
    margin: 0 auto;
    max-width: 1400px;
    overflow: hidden;
  }

  &-list {
    display: flex;
    flex-wrap: nowrap;
    padding: 0;
    transition: $transition-slider;
  }

  &-item {
    padding: 0 10px;
    
    &:before {
      display: none;
    }

    .team-image,
    img {
      border-radius: 50%;
      display: block;
      height: 115px;
      margin: 0 auto 15px auto;
      width: 115px
    }

    div.team-image {
      align-items: center;
      background: rgba($color-bg, .5);
      display: flex;
      font-size: 24px;
      font-weight: 700;
      justify-content: center;
    }

    .team-name {
      font-size: $font-subtitle-size;
      font-weight: 500;
      margin: 10px 0;
      line-height: 1.1;
    }

    .team-job {
      font-size: 14px;
      margin: 0;
    }
  }

  .prev,
  .next {
    position: absolute;
    top: 125px;
    transform: translateY(-50%);
  }

  .prev {
    left: 60px
  }

  .next {
    right: 60px
  }

  .slider-dots {
    bottom: 70px;

    .dot {
      background: $color-bg;
      border: none;
      height: 10px;
      opacity: .5;
      width: 10px;

      &:active,
      &:focus,
      &:hover {
        background: $color-text;
        border: none;
      }
        
      &.is-active {
        background: $color-text;

        &:active,
        &:focus,
        &:hover {
          border: none
        }
      }
    }
  }

  @for $i from 1 through 32 {
    .slider-team-list[data-count="#{$i}"] {
      width: calc(25% * #{$i});

      .slider-team-item {
        width: calc(100% / #{$i});
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .slider-team {
    .slider-team-inner {
      max-width: 1040px;

      .team-name {
        font-size: 18px;
      }

      .team-job {
        font-size: 14px;
      }
    }

    .prev {
      left: 30px
    }
  
    .next {
      right: 30px
    }
  }
}

@media screen and (max-width: 1200px) {
  .slider-team {
    &-inner {
      margin: 0 40px;
    }
    
    .prev {
      left: 0px
    }
  
    .next {
      right: 0px
    }

    @for $i from 1 through 32 {
      .slider-team-list[data-count="#{$i}"] {
        width: calc((100% / 3) * #{$i});
  
        .slider-team-item {
          width: calc(100% / #{$i});
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .slider-team {
    margin: 50px auto;

    &-wrapper {
      padding: 0 0 100px 0;
    }

    .slider-dots {
      bottom: 50px
    }
  }
}

@media screen and (max-width: 840px) {
  .slider-team {
    @for $i from 1 through 32 {
      .slider-team-list[data-count="#{$i}"] {
        width: calc(50% * #{$i});
  
        .slider-team-item {
          width: calc(100% / #{$i});
        }
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .slider-team {
    &-wrapper {
      padding: 0 0 60px 0;
    }

    .slider-dots {
      bottom: 25px
    }

    @for $i from 1 through 32 {
      .slider-team-list[data-count="#{$i}"] {
        width: calc(100% * #{$i});
  
        .slider-team-item {
          width: calc(100% / #{$i});
        }
      }
    }
  }
}