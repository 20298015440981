.slider-testimonials {
  border-bottom: 1px solid $color-border;
  margin: 70px 0;
  overflow: hidden;
  position: relative;

  &:before,
  &:after {
    content: '';
    height: 115px;
    position: absolute;
    top: 47%;
    transform: translateY(-50%);
    width: 150px;
    z-index: map-get($z-index, bottom );
  }

  &:before {
    background: $gradient-slider;
    left: 0;
  }

  &:after {
    background: $gradient-slider-inverse;
    right: 0;
  }

  
  &-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 auto;
    max-width: 1220px;
    position: relative;
  }

  &-inner {
    margin: 0 auto;
    max-width: 810px;
    order: 2;
    overflow: hidden;
    width: calc(100% - 40px);
  }

  &-list {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 0;
    margin-left: -500%;
    padding: 0;
    transition: $transition-slider;
  }

  &-item {
    text-align: center;

    &:before {
      display: none;
    }
  }

  @for $i from 1 through 50 {
    .slider-testimonials-list[data-count="#{$i}"] {
      width: calc(100% * #{$i});

      .slider-testimonials-item {
        width: 100%;
      }
    }
  }

  &-dots {
    display: block;
    margin: auto;
    max-width: 1020px;
    overflow: hidden;
    width: 100%;
  }

  &-dots-wrapper {
    display: block;
    margin: auto;
    max-width: 115px;
    width: 100%;
  }

  &-dots-inner {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 0;
    margin-left: -575px;
    order: 1;
    padding: 0;
    transition: $transition-slider-fast;
    width: 100%;

    .dot {
      border-radius: 50%;
      cursor: pointer;
      display: block;
      flex-shrink: 0;
      height: 115px;
      margin: 0 0;
      overflow: hidden;

      img {
        display: block;
        height: auto;
        opacity: 0.5;
        transform: scale(0.65);
        transition: $transition-slider;
        width: 100%;
      }

      &:hover img {
        opacity: 1;
      }

      &.is-active img {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  @for $i from 1 through 50 {
    .slider-testimonials-dots-inner[data-count="#{$i}"] {
      width: calc(115px * #{$i});

      .dot {
        width: 115px;
      }
    }
  }

  .prev,
  .next {
    position: absolute;
    top: 58px;
    transform: translateY(-50%);
  }

  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }
}

@media screen and (max-width: 1300px) {
  .slider-testimonials {
    .prev {
      left: 20px;
    }
  
    .next {
      right: 20px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .slider-testimonials {
    margin: 50px 0;

    &:before,
    &:after {
      top: 40%
    }

    .section-title {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 560px) {
  .slider-testimonials {
    &:before,
    &:after {
      top: 35%;
    }
  }
}
