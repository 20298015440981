.slider-gallery {
  border-bottom: 1px solid $color-border;
  margin: 70px auto;
  overflow: hidden;
  padding: 0 20px;

  .wrapper {
    padding: 0;
  }

  .section-title {
    margin-bottom: 20px;
  }
  
  .description.below-title {
    margin-top: 20px;
  }

  &-inner {
    margin: 0 auto;
    max-width: 750px;
    position: relative;
  }

  &-list {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    margin: 50px 0 75px 0;
    padding: 0;
    transition: $transition-slider;
    z-index: map-get($z-index, bottom );
  }

  &-item {
    overflow: hidden;
    position: relative;
    width: 750px;
    z-index: map-get($z-index, bottom );

    &:before {
      display: none;
    }

    .image-wrapper {
      border-radius: 3px;
      height: 560px;
      overflow: hidden;
      width: 100%
    }

    .image {
      display: block;
      height: 100%;
      max-width: 100%;
      object-fit: cover;
      width: 100%;
    }

    .image-title {
      font-size: 16px;
      font-weight: 500;
      margin: 45px 0 10px 0;
      opacity: 0;
      padding: 0 70px;
      text-align: center;
      transition: $transition-basic;
    }

    .image-descriptionription {
      margin: 10px 0;
      opacity: 0;
      padding: 0 70px;
      text-align: center;
      transition: $transition-basic;
    }

    &.is-active {
      .image-title,
      .image-descriptionription {
        opacity: 1;
      }
    }
  }

  @for $i from 1 through 50 {
    .slider-gallery-list[data-count="#{$i}"] {
      width: calc(100% * #{$i});

      .slider-gallery-item {
        margin: 0 70px;
        width: calc((100% / #{$i}) - 140px);
      }
    }
  }

  .prev,
  .next {
    background: none;
    color: $color-heading;
    position: absolute;
    top: 280px;
    z-index: map-get($z-index, base );

    &:active,
    &:focus,
    &:hover {
      color: var(--color-primary)
    }

    &.is-hidden {
      display: none;
    }
  }

  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .slider-gallery {
    margin: 50px auto;

    .section-title {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 840px) {
  .slider-gallery {
    &-item {
      .image-wrapper {
        height: 450px;
      }
    }

    .next,
    .prev {
      top: 220px
    }
  }
}

@media screen and (max-width: 720px) {
  .slider-gallery {
    &-item {
      .image-descriptionription,
      .image-title {
        padding: 0 20px;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .slider-gallery {
    padding: 0;

    .section-title,
    .description {
      padding: 0 20px;
    }

    &-item {
      .image-wrapper {
        height: 300px;
      }

      .image-descriptionription, 
      .image-title {
        padding: 0;
      }
    }

    @for $i from 1 through 50 {
      .slider-gallery-list[data-count="#{$i}"] {
        .slider-gallery-item {
          margin: 0 40px;
          width: calc((100% / #{$i}) - 80px);
        }
      }
    }

    .prev,
    .next {
      top: 145px;
    }
  }
}