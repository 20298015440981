*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: $color-light!important;
  color: $color-text;
  font-family: $font-text;
  font-size: $font-base-size;
  font-weight: 400;
  line-height: $font-line-height;
  margin: 0;
  padding: 0;

  &.no-scroll {
    overflow: hidden;
  }

  section:last-of-type {
    margin-bottom: 0;
  }
}

.wrapper {
  margin: 0 auto;
  max-width: $dimension-max-width;
  padding: $dimension-padding;
  width: 100%;

  &-wide {
    @extend .wrapper;
    max-width: $dimension-wide-width;
  }

  &-narrow {
    @extend .wrapper;
    max-width: $dimension-narrow-width;
  }

  &-post {
    @extend .wrapper;
    max-width: $dimension-post-width;
  }
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

h1,
.page-title {
  color: $color-heading;
  font-size: $font-title-size;
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
}

h2,
.subtitle {
  color: $color-heading;
  font-weight: 600;
  font-size: $font-subtitle-size;
  margin: 20px 0;
  line-height: 1.2;
}

h3, h4, h5, h6 {
  color: $color-heading;
  font-weight: 600;
  width: 100%;
}

.section-title {
  font-size: $font-title-big-size;
  margin: 40px 0;
  line-height: 1.2;

  &.center {
    text-align: center;
  }
}

p,
.description {
  margin: 35px 0;

  &.below-title {
    font-size: $font-subtitle-size;
    font-weight: 500;
  }

  &.center {
    text-align: center;
  }
}

p {
  margin: 35px 0;
}

ul {
  list-style: none;
  padding-left: 20px;

  li {
    margin: 10px 0;
    position: relative;

    p {
      margin: 0;
    }

    &:before {
      background: var(--color-primary);
      content: '';
      height: 7px;
      left: -20px;
      position: absolute;
      top: 10px;
      width: 7px;
    }

    ul {
      margin: 0;
      padding-left: 30px;

      li {
        &:before {
          background: transparent;
          border: 1px solid var(--color-primary);
          content: '';
          height: 7px;
          left: -20px;
          position: absolute;
          top: 10px;
          width: 7px;
        }
      }
    }
  }
}

ol {
  li {
    ol {
      list-style: lower-alpha;
      margin: 0;
      padding-left: 40px;
    }

    p {
      margin: 0;
    }
  }
}

.slider-dots {
  align-items: center;
  bottom: 40px;
  display: flex;
  justify-content: center;
  margin: 0;
  left: 50%;
  list-style: none;
  padding: 0;
  position: absolute;
  transform: translateX(-50%);
  z-index: map-get($z-index, bottom );

  .dot {
    background: transparent;
    border-radius: 50%;
    border: 2px solid $color-light;
    cursor: pointer;
    display: block;
    height: 13px;
    margin: 0 6px;
    transition: $transition-basic;
    width: 13px;

    &:active,
    &:focus,
    &:hover {
      border: 2px solid var(--color-primary);
    }
      
    &.is-active {
      background: $color-light;

      &:active,
      &:focus,
      &:hover {
        border: 2px solid $color-light;
      }
    }
  }
}

.prev,
.next {
  align-items: center;
  background: $color-bg;
  border-radius: 50%;
  color: $color-light;
  display: flex;
  justify-content: center;
  height: 40px;
  transition: $transition-basic;
  width: 40px;
  z-index: map-get($z-index, middle );

  .icon {
    fill: $color-light;
    height: 16px;
    position: relative;
    width: 16px;
  }

  &:hover {
    background: $color-text;
  }
}

.prev {
  .icon {
    top: -1px;
    transform: rotate(-180deg);
  }
}

.next {
  &:before {
    border-width: 2px 2px 0 0;
    left: 48%;
  }
}

.cookies {
  background: $color-light;
  bottom: 0;
  box-shadow: $shadow-top;
  font-size: 16px;
  line-height: 1.4;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  z-index: 10000001;

  .wrapper {
    align-items: center;
    color: $color-text;
    display: flex;
    height: 100%;
    justify-content: center;
    padding-right: 70px;
  }

  .close {
    cursor: pointer;
    right: 40px;
    position: absolute;

    svg {
      display: block;
      fill: $color-text;
      height: 24px;
      width: 24px
    }

    &:hover svg {
      fill: var(--color-primary);
    }
  }
}

@media screen and (max-width: 1300px) {
  h1,
  .page-title {
    font-size: 30px;
  }

  .section-title {
    font-size: 36px;
  }

  .description.below-title {
    font-size: 18px;
  }
}

@media screen and (max-width: 1024px) {
  h1,
  .page-title {
    font-size: 28px;
  }

  .section-title {
    font-size: 32px;
  }

  .slider-dots {
    bottom: 24px;
  }
}

@media screen and (max-width: 720px) {
  h1,
  .page-title {
    font-size: 26px;
  }

  .section-title {
    font-size: 28px;
  }

  .cookies {
    font-size: 12px;

    .wrapper {
      padding-right: 50px;
    }

    .close {
      right: 20px
    }
  }
}

@media screen and (max-width: 560px) {
  h1,
  .page-title {
    font-size: 22px;
  }

  .section-title {
    font-size: 24px;
  }

  .description, p {
    margin: 25px 0;
  }

  .description.below-title {
    font-size: 16px;
  }

  .cookies {
    font-size: 12px;

    .wrapper {
      padding-right: 40px;
    }
    
    .close {
      right: 10px
    }
  }
}