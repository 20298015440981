.block-table {
  border-bottom: 1px solid $color-border;
  margin: 70px 0;
	overflow: hidden;

	.section-title {
		margin-top: 0;
	}

	& + .block-text-on-bg {
		margin-top: -70px;
	}
}

.tablinks-wrapper {
	align-items: center;
	display: flex;
	justify-content: center;
	padding: 0;

	li {
		border-bottom: 4px solid transparent;
		color: $color-text-light;
		cursor: pointer;
		font-size: 21px;
		font-weight: 500;
		margin: 0 6px;
		padding: 5px 12px;
		transition: $transition-basic;

		&::before {
			display: none;
		}

		&:hover {
			color: $color-heading;
		}

		&.is-active {
			border-bottom: 4px solid var(--color-primary);
			color: $color-heading;
		}
	}
}

.tabcontent {
	left: 0;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	transition: $transition-basic;
	z-index: map-get($z-index, base );
	width: 100%;

	&.is-active {
		opacity: 1;
		pointer-events: all;
		position: relative;
		z-index: map-get($z-index, bottom );
	}
}

.tables {
  margin: 40px auto 70px auto;
  max-width: 1140px;
	padding: 0;
	position: relative;
  
  li {
		margin: 0;
		min-height: 160px;

    &:before {
      display: none;
    }
  }
}

.wrapper-narrow > .tables {
	margin-top: 0;
}

.table {
	width: 100%;

	&-head {
		background: $color-bg-light;
		color: $color-heading;
		display: flex;

		&-cell {
			border: 1px solid $color-border;
			border-right: none;
			border-bottom: none;
			flex: 1;
			font-weight: 500;
			padding: 20px;
			text-align: center;

			&:last-child {
				border-right: 1px solid $color-border;
			}
		}
	}

	&-body-row {
		display: flex;

		&-cell {
			border: 1px solid $color-border;
			border-right: none;
			border-bottom: none;
			flex: 1;
			padding: 20px;
			text-align: center;

			&:last-child {
				border-right: 1px solid $color-border;
			}
		}

		&:last-of-type {
			.table-body-row-cell {
				border-bottom: 1px solid $color-border;
			}
		}
	}

	.mobile-header {
		display: none;
	}
}

@media screen and (max-width: 1024px) {
  .block-table{
		margin: 50px auto;

		.section-title {
			margin: 0 0 20px 0;
		}

		& + .block-text-on-bg {
			margin-top: -50px;
		}
	}

	.tablinks-wrapper {
		flex-wrap: wrap;
		
		li {
			font-size: 18px;
		}
	}

	.tables {
		margin-bottom: 50px
	}
}

@media screen and (max-width: 720px) {
	.table {
		&-head {
			display: none
		}

		&-body-row {
			border: 1px solid $color-border;
			flex-wrap: wrap;
			padding: 15px;

			&-cell {
				border: none;
				flex: inherit;
				padding: 0;
				text-align: left;
				width: 100%;

				&:last-child {
					border-right: none;
				}
			}

			& + .table-body-row {
				border-top: none;
			}

			&:last-of-type .table-body-row-cell {
				border-bottom: none;
			}
		}

		.mobile-header {
			display: inline-block;
			font-weight: 700;
			margin-right: 10px;
		}
	}
}

@media screen and (max-width: 560px) {
	.tablinks-wrapper li {
		font-size: 16px;
	}

	.tables {
		margin-top: 20px
	}

	.table-body-row {
		padding-bottom: 5px;
	}

	.mobile-header {
		width: 100%
	}

	.value {
		display: inline-block;
		padding-bottom: 10px;
	}
}