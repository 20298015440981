.edd-root,
.edd-root *,
.edd-root *::before,
.edd-root *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.edd-root {
  color: $color-text;
  display: inline-block;
  position: relative;
  user-select: none;
  width: 100%
}

.edd-root-disabled {
  color: #ccc;
  cursor: not-allowed;
}

.edd-head {
  background: $color-light;
  border: 1px solid $color-border;
  border-radius: 3px;
  height: 46px;
  line-height: 44px;
  overflow: hidden;
  padding: 0 10px;
  position: relative;
}

.edd-root-focused .edd-head {
  border-color: var(--color-secondary);
}

.edd-root-invalid .edd-head {
    border-color: $color-danger;
}

.edd-value {
  padding: 0;
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 100%;
}

.edd-arrow {
  position: absolute;
  width: 18px;
  height: 10px;
  top: 50%;
  right: 15px;
  transition: transform 150ms;
  pointer-events: none;
  color: $color-heading;
}

.edd-arrow::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  top: -5px;
  right: 0;
  transform: rotate(45deg);
  transform-origin: 50% 25%;
}

.edd-root-open .edd-arrow {
  transform: rotate(180deg);
  right: 8px;
  top: calc(50% - 6px);
}

.edd-root-open .edd-arrow,
.edd-root:not(.edd-root-disabled):not(.edd-root-open) .edd-head:hover .edd-arrow {
  color: var(--color-primary);
}

.edd-value,
.edd-option,
.edd-group-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.edd-root:not(.edd-root-disabled) .edd-value,
.edd-option {
  cursor: pointer;
}

.edd-select {
  position: absolute;
  opacity: 0;
  width: 100%;
  left: -100%;
  top: 0;
}

.edd-root-native .edd-select {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.edd-body {
  background: $color-light;
  border-radius: 3px;
  border: 2px solid var(--color-primary);
  left: 0;
  margin: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  right: 0;
  transform: scale(0.95);
  width: 100%;
  z-index: map-get($z-index, bottom );
}

.edd-root-open .edd-body {
  opacity: 1;
  pointer-events: all;
  transform: scale(1);
  transition: opacity 200ms, transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.edd-root-open-above .edd-body {
  bottom: 100%;
}

.edd-root-open-below .edd-body {
  top: calc(100% + 2px);
}

.edd-items-list {
  overflow: auto;
  max-height: 350px!important;
  transition: $transition-basic;
  -webkit-overflow-scrolling: touch;
}

.edd-option {
  font-size: 16px;
  padding: 10px 0;
  border: none;
  padding: 10px;
  transition: $transition-basic;
}

.edd-group-has-label .edd-option {
  padding-left: 14px;
}

.edd-option-selected {
  color: var(--color-primary);
}

.edd-option-focused:not(.edd-option-disabled) {
  background: var(--color-primary);
  color: $color-light;
}

.edd-option-disabled,
.edd-group-disabled .edd-option {
  cursor: default;
  color: #ccc;
}