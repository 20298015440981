.block-media-text {
  align-items: flex-start;
  display: flex;
  margin: 100px auto;
  max-width: $dimension-narrow-width;
  overflow: hidden;

  &.right {
    flex-direction: row-reverse;

    .image-wrapper {
      justify-content: flex-start;
      padding-left: 80px;
      padding-right: 20px;

      &-inner {
        &:before {
          left: inherit;
          right: -100px;
        }
      }
    }

    .text-wrapper {
      padding-right: 80px;
      padding-left: 0;
    }
  }

  &.light {
    .section-title,
    p,
    .description {
      color: $color-light
    }
  }

  .image-wrapper {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    padding-top: 100px;
    padding-right: 80px;
    width: 50%;

    &-inner {
      display: block;
      height: auto;
      position: relative;
      width: 510px;

      .image {
        border-radius: 5px;
        display: block;
        height: auto;
        position: relative;
        width: 100%;
      }

      &:before {
        background: var(--color-primary);
        border-radius: 5px;
        content: '';
        height: 510px;
        left: -100px;
        opacity: 0.07;
        position: absolute;
        top: -100px;
        width: 510px;
      }
    }
  }

  .text-wrapper {
    padding-left: 80px;
    width: 50%
  }

  .btn {
    margin-top: 20px;
  }

  &.has-background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 70px 0;
    max-width: inherit;
    padding: 100px 0;

    .image-wrapper {
      display: none;
    }

    .text-wrapper {
      left: 0;
      margin: 0;
      max-width: 1380px;
      padding: 0 15px;
      position: relative;
    }

    &.left {
      flex-direction: row-reverse;
      
      .text-wrapper {
        padding: 0 100px 0 15px;
      }
    }

    &.right {
      flex-direction: row;
      
      .text-wrapper {
        padding: 0 15px 0 100px;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .block-media-text {
    .image-wrapper {
      padding-left: 20px;
    }

    .text-wrapper {
      padding-right: 20px;
    }

    &.right .text-wrapper {
      padding-left: 20px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .block-media-text {
    .image-wrapper {
      padding-right: 50px
    }

    &.right {
      .image-wrapper {
        padding-left: 50px;
      }
    }

    &.has-background {
      &.right .text-wrapper {
        padding: 0 15px 0 80px;
      }

      &.left .text-wrapper {
        padding: 0 80px 0 15px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .block-media-text {
    margin: 50px auto;

    .image-wrapper {
      padding-right: 40px;
    }

    .text-wrapper {
      padding-left: 0px;
    }

    &.right {
      .image-wrapper {
        padding-left: 40px;
      }

      .text-wrapper {
        padding-right: 0;
      }
    }

    &.has-background {
      padding: 50px 0;

      &.right .text-wrapper {
        padding: 0 15px 0 50px;
      }

      &.left .text-wrapper {
        padding: 0 50px 0 15px;
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .block-media-text {
    flex-wrap: wrap;

    .image-wrapper {
      justify-content: center;
      padding-left: 100px;
      padding-right: 0;
      width: 100%
    }

    .text-wrapper {
      padding: 0 20px;
      margin: 0 auto;
      max-width: 610px;
      width: 100%;
    }

    &.right {
      .image-wrapper {
        justify-content: center;
        padding-left: 0;
        padding-right: 100px;
      }

      .text-wrapper {
        padding: 0 20px;
      }
    }

    &.has-background {
      padding: 30px 0;
    }
  }
}

@media screen and (max-width: 720px) {
  .block-media-text {
    .image-wrapper-inner:before {
      height: 420px;
      width: 420px;
    }

    &.has-background {
      &.right .text-wrapper {
        padding: 0 15px 0 30px;
      }

      &.left .text-wrapper {
        padding: 0 30px 0 15px;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .block-media-text {
    .image-wrapper {
      padding-left: 70px;
      padding-right: 20px;
      padding-top: 50px;
        
      &-inner {
        &:before {
          left: -50px;
          top: -50px;
          height: 280px;
          width: 280px;
        }
      }
    }

    &.right .image-wrapper {
      padding-left: 20px;
      padding-right: 70px;
    }

    &.has-background {
      padding: 5px 0;
      
      &.right .text-wrapper {
        padding: 0 15px 30px 15px;
      }

      &.left .text-wrapper {
        padding: 0 15px 30px 15px;
      }
    }
  }
}