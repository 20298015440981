a,
.link {
  color: var(--color-primary);
  text-decoration: underline;
  transition: $transition-basic;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }

  &--inverse {
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
}

.link-dark {
  @extend .link;
  color: $color-heading;

  &:active,
  &:focus,
  &:hover {
    color: var(--color-primary);
  }
}

.link,
.link-dark {
  &.with-icon {
    align-items: center;
    display: flex;

    .icon {
      display: inline-block;
      fill: var(--color-primary);
      height: 20px;
      width: 20px;
    }
  }
}