.slider-wrapper { 
  background-color: $color-text;
  overflow: hidden;
  position: relative;

  .slider-list {
    display: flex;
    list-style: none;
    margin: 0;
    max-width: inherit;
    padding: 0;
    position: relative;
    transition: $transition-slider;
  }
  
  .slider-item {
    background-repeat: no-repeat;
    background-size: contain!important;
    color: $color-light;
    display: block;
    height: 750px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-align: center;
    z-index: map-get($z-index, bottom );

    &:before {
      background-color: rgba($color-dark, .3);
      border-radius: 0;
      content: '';
      display: flex;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: map-get($z-index, bottom );
    }

    img {
      display: block;
      height: 100%;
      max-width: 100%;
      object-fit: cover;
      width: 100%;
      z-index: map-get($z-index, base );
    }

    .text-wrapper {
      left: 50%;
      max-width: 1650px;
      padding: 0 50px;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: map-get($z-index, middle );
      width: 100%;

      h1.subtitle,
      p {
        color: $color-light;
        margin: 0 auto;
        max-width: 800px;
        text-shadow: 0 3px 6px rgba($color-dark, .16);
        width: 100%;
      }

      h1.subtitle {
        font-size: $font-header-size;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 30px;
      }

      p {
        font-size: $font-subtitle-size;
        font-weight: 500;
        margin: 30px auto
      }
    }
  }

  .prev,
  .next {
    opacity: 0;
    position: absolute;
  }

  @for $i from 1 through 32 {
    .slider-list[data-count="#{$i}"] {
      width: calc(100% * #{$i});

      .slider-item {
        width: calc(100% / #{$i});
      }
    }
  }
}

.page-main {
  &.light {
    .slider-wrapper .slider-item .text-wrapper h1.subtitle,
    .slider-wrapper .slider-item .text-wrapper p {
      color: $color-light;
    }
  }

  &.dark {
    .slider-wrapper .slider-item .text-wrapper h1.subtitle,
    .slider-wrapper .slider-item .text-wrapper p {
      color: $color-heading;
    }
  }
}

@media screen and (max-width: 1300px) {
  .slider-wrapper .slider-item {
    height: 550px;
  }
}

@media screen and (max-width: 1024px) {
  .slider-wrapper .slider-item .text-wrapper h1.subtitle {
    font-size: 36px;
  }
}

@media screen and (max-width: 720px) {
  .slider-wrapper .slider-item .text-wrapper {
    padding: 0 30px;
    
    h1.subtitle {
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 560px) {
  .slider-wrapper .slider-item {
    height: 550px;

    .text-wrapper {
      h1.subtitle {
        font-size: 26px;
      }
    
      p {
        font-size: 19px;
        margin: 20px 0 30px 0;
      }
    }
  }
}