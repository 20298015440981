.block-with-page-title {
  border-bottom: 1px solid $color-border;
  padding-bottom: 40px;
  margin: 70px 0;

  p,
  .description {
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  & + .has-background,
  & + .block-text-on-bg {
    margin-top: 0px;
  }
}

.site-main > .block-with-page-title {
  border-bottom: none;
}

.block-contact {
  border-bottom: 1px solid $color-border;
  margin: 70px 0;

  .section-title {
    margin-bottom: 20px;
  }

  p.below-title,
  .description.below-title {
    margin-top: 20px;
  }

  &-map {
    border-bottom: none;
  }

  &.has-background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
  }

  &.without-opening-hours {
    .contact-info {
      justify-content: center;
    }
  }

  &.light {
    .section-title,
    .description {
      color: $color-light
    }

    .contact-info {
      color: $color-light;

      .contact-address,
      .contact-title,
      .contact-date,
      .contact-hours {
        color: $color-light
      }

      .link-dark {
        color: $color-light;
      }
    }
  }

  & + .has-background,
  & + .block-text-on-bg {
  //& + .block-with-page-title
    margin-top: -70px;
  }

  .contact-date{
    > a{
      display: block;
    }
  }
}

.contact {
  &-info {
    align-items: flex-start;
    display: flex;
    margin: 0 auto;
    max-width: $dimension-post-width;
    padding: 0 0 40px 0;

    &-item {
      line-height: 1.6;
      padding-right: 25px;
      width: calc(100% / 3);

      &:before {
        display: none;
      }
    }
  }

  &-title {
    align-items: center;
    display: flex;
    font-size: 21px;
    font-weight: 500;

    .icon {
      background: var(--color-secondary);
      border-radius: 50%;
      display: block;
      fill: $color-light;
      height: 40px;
      margin-right: 20px;
      padding: 11px;
      width: 40px
    }
  }

  &-address {
    font-style: normal;
    padding-left: 60px;
  }

  &-date {
    padding-left: 60px;

    a {
      text-decoration: none;

      &:hover {
        color: var(--color-secondary);
        text-decoration: underline;
      }
    }
  }

  &-hours {
    display: flex;
    flex-wrap: wrap;
    padding-left: 60px;

    dt,
    dd {
      width: 50%
    }

    dt {
      font-size: 16px;
      font-weight: 600;
      text-align: left;
    }

    dd {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-left: 10px;
      width: calc(50% - 10px)
    }

    .hours {
      display: flex;
      justify-content: space-between;
      width: 120px;

      span {
        display: inline-block;
        width: 45px;
      }
    }
  }

  &-map-wrapper {
    margin-bottom: 50px;
    position: relative;

    .btn-view-map {
      bottom: 40px;
      box-shadow: $shadow-bottom;
      display: block;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 220px
    }
  }

  &-map {
    border-radius: 4px;
    height: 390px;
    margin: 0 0 10px 0;
    overflow: hidden;
    position: relative;

    iframe {
      height: 100%;
      
      width: 100%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .block-with-page-title {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .contact {
    &-info {
      flex-wrap: wrap;

      &-item {
        padding: 0;
        text-align: center;
        width: 100%
      }
    }

    &-title {
      justify-content: center;
    }

    &-date,
    &-address {
      padding: 0;
    }

    &-hours {
      padding: 0;

      dt {
        text-align: right;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .block-contact.has-background {
    padding: 50px 0;
  }
}

@media screen and (max-width: 560px) {
  .block-with-page-title {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .block-contact.has-background {
    padding: 30px 0;
  }
}