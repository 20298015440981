.slider-partners {
  border-bottom: 1px solid $color-border;
  margin: 70px 0;
  overflow: hidden;
  text-align: center;
  
  .section-title {
    margin-bottom: 20px;
  }

  .description.below-title {
    margin-top: 20px;
  }
  
  &-wrapper {
    padding: 40px 0 160px 0;
    position: relative;
  }

  &-inner {
    margin: 0 auto;
    max-width: 1400px;
    overflow: hidden;
  }

  &-list {
    display: flex;
    flex-wrap: nowrap;
    padding: 0;
    transition: $transition-slider-fast;
  }

  &-item {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 10px;

    &:before {
      display: none;
    }

    img {
      display: block;
      height: auto;
      margin: 0 auto;
      max-width: 100%;
    }
  }

  .prev,
  .next {
    position: absolute;
    top: 105px;
    transform: translateY(-50%);

    &.is-hidden {
      display: none;
    }
  }

  .prev {
    left: 60px
  }

  .next {
    right: 60px
  }

  .slider-dots {
    bottom: 70px;

    .dot {
      background: $color-bg;
      border: none;
      height: 10px;
      opacity: .5;
      width: 10px;

      &:active,
      &:focus,
      &:hover {
        background: $color-text;
        border: none;
      }
        
      &.is-active {
        background: $color-text;

        &:active,
        &:focus,
        &:hover {
          border: none
        }
      }
    }
  }

  @for $i from 1 through 50 {
    .slider-partners-list[data-count="#{$i}"] {
      width: calc((100% / 7) * #{$i});

      .slider-partners-item {
        width: calc(100% / #{$i});
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .slider-partners {
    &-inner {
      max-width: 850px;
      width: calc(100% - 100px);
    }

    @for $i from 1 through 50 {
      .slider-partners-list[data-count="#{$i}"] {
        width: calc((100% / 5) * #{$i});
      }
    }

    .prev {
      left: 0;
    }
  
    .next {
      right: 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .slider-partners {
    .section-title {
      margin-top: 0;
    }

    &-wrapper {
      padding: 0 0 100px 0;
    }
    
    @for $i from 1 through 50 {
      .slider-partners-list[data-count="#{$i}"] {
        width: calc((100% / 3) * #{$i});
      }
    }

    .prev,
    .next {
      top: 65px
    }
  }
}

@media screen and (max-width: 720px) {
  .slider-partners {
    @for $i from 1 through 50 {
      .slider-partners-list[data-count="#{$i}"] {
        width: calc((100% / 2) * #{$i});
      }
    }
  }
}
