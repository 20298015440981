.block-survey{
  border-bottom: none;

  .logo-wrapper{
    width: 100%;

    &-inner{
      margin: auto;
      text-align: center;
      padding-bottom: 1rem;
    }
  }

  .questions{
    width: 100%;

    &-inner{
      margin: auto;
      padding: 1rem;
      max-width: 800px;
      border: 1px solid $color-border;
    }

    ul{
      margin: 0;
      padding: 0;
      list-style: none;
      li{
        margin: 0;
        padding: 0.5rem 0;
        &:before{
          display: none;
        }

        label{
          width: 100%;
          &.font-bold{
            font-weight: 700;
          }
          .required{
            font-weight: 900;
            color: $color-danger;
          }
        }
        textarea{
          width: 100%;
          height: 100px;
        }

        .error-message{
          display: none;
          color: $color-danger;
        }
        &.error{
          .error-message{
            display: block;
          }
        }
      }
    }

  }

  .submit-button{
    width: 100%;

    &-inner{
      margin: auto;
      padding: 1rem;
      max-width: 800px;
      text-align: center;

      .error-message{
        display: none;
        margin-bottom: 1rem;
        color: $color-danger;
      }
      &.error{
        .error-message{
          display: block;
        }
      }

    }

  }

}