.page-footer {
  margin: 50px 0px;
  
  .wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  .logo {
    display: block;
    height: auto;
    margin-right: 45px;
    width: 115px;

    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }

  .copyrights {
    margin: 25px 0;
    
    span {
      display: inline-block;
      margin-right: 45px;
    }
  }

  .footer-link {
    color: $color-text;
    margin-right: 40px;
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
      color: var(--color-primary);
      text-decoration: underline;
    }
  }

  .payments-block {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    width: 100%;

    img {
      display: block;
      height: auto;
      margin-left: 10px;
      width: 60px;
    }
  }

  > .payments-block{
    padding: 0 20px;
    margin: 0 auto;
    max-width: 1860px;
  }
}

.social-block {
  align-items: center;
  display: flex;
  margin-left: auto;

  &-text {
    font-weight: 500;
    margin-right: 15px;
  }

  &-list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }

  &-item {
    margin: 0;

    &:before {
      display: none;
    }
  }

  &-link {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    margin: 6px;
    transition: $transition-basic;
    width: 40px;

    .icon {
      fill: $color-light;
      height: 20px;
      width: 20px;
    }

    &.facebook {
      background: $color-social-facebook;
    }

    &.twitter {
      background: $color-social-twitter;
    }

    &.linkedin {
      background: $color-social-linkedin;
    }

    &.youtube {
      background: $color-social-youtube;
    }

    &.instagram {
      background: $color-social-instagram;

      .icon {
        fill: $color-dark
      }
    }

    &.pinterest {
      background: $color-social-pinterest;
    }
    
    &.tripadvisor {
      background: $color-social-tripadvisor;

      .icon {
        fill: $color-dark
      }
    }

    &:active,
    &:focus,
    &:hover {
      box-shadow: $shadow-bottom-dark;
      transform: scale(.9);
    }
  }
}

@media screen and (max-width: 1400px) {
  .page-footer .copyrights span {
    margin-right: 30px;
  }

  .page-footer .footer-link {
    margin-right: 30px;
  }

  .social-block {
    &-text {
      margin-right: 10px;
    }
  
    &-link {
      margin: 4px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .page-footer {
    margin: 20px 0 30px 0;

    .wrapper {
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .logo {
      margin: 0;
      width: 100%;

      img {
        margin: 0 auto;
        max-width: 115px;
      }
    }

    .payments-block {
      justify-content: center;
      margin-top: 15px;
    }
  }

  .social-block {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    &-text {
      margin: 0
    }

    &-list {
      justify-content: center;
      width: 100%;
    }
  }
}

@media screen and (max-width: 720px) {
  .page-footer {
    .copyrights {
      text-align: center;
      width: 100%;
      
      span {
        font-size: 14px;
        margin: 0;
      }
    }

    .description, p {
      margin: 15px 0 5px 0;
    }

    .footer-link {
      font-size: 14px;
      margin: 0 5px 20px 0;
    }

    .payments-block {
      font-size: 12px;

      img {
        margin-left: 5px;
        width: 50px;
      }
    }
  }

  .social-block-text {
    font-size: 14px;
  }
}