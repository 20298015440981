.block-pricetable {
  border-bottom: 1px solid $color-border;
  margin: 70px 0;
  padding: 0 20px;

  .pricetable-select {
    margin: 0 auto;
    max-width: 485px;
    width: 100%;

    .edd-group-label, .edd-option, .edd-value {
      white-space: normal;
      text-overflow: unset;
    }

    .edd-head {
      background: var(--color-primary);
      border: 1px solid transparent;
      border-radius: 3px;
      color: $color-light;
      font-size: 16px;
      font-weight: 500;
      height: 70px;
      line-height: 1.2;
      outline: none;
      padding: 5px 60px 5px 20px;
      position: relative;

      .edd-value {
        align-items: center;
        display: flex;
      }
    }

    .edd-arrow {
      background: rgba($color-heading, 0.1);
      color: $color-light;
      height: 100%;
      top: 0;
      transform: none;
      transition: $transition-basic;
      right: 0;
      width: 60px;

      &:before {
        height: 10px;
        left: calc(50% + 2px);
        top: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        width: 10px
      }
    }

    .edd-root-open .edd-arrow {
      color: $color-light;

      &:before {
        left: calc(50% - 1px);
        top: calc(50% + 7px);
        transform: translateX(-50%) translateY(-50%) rotate(225deg);
      }
    }

    .edd-option-focused:not(.edd-option-disabled) {
      background: rgba($color-heading, 0.18);
      color: $color-light;
    }

    .edd-root:not(.edd-root-disabled):not(.edd-root-open) .edd-head:hover .edd-arrow {
      background: rgba($color-heading, 0.025);
      color: $color-light;
      line-height: 1.2;
    }
  }

  .pricetable {
    &-wrapper {
      display: none;
      opacity: 0;
      pointer-events: none;

      &.is-active {
        display: block;
        opacity: 1;
        pointer-events: auto;
      }
    }

    &-tables {
      margin: 40px auto 100px auto;
      max-width: 1140px;
      padding: 0;
      position: relative;
      
      li {
        margin: 0;
        min-height: 70px;
    
        &:before {
          display: none;
        }
      }
    }

    &-row {
      align-items: center;
      border: 1px solid $color-border;
      border-radius: 3px;
      display: flex;
      margin: 10px 0;
      padding: 16px 25px;
      position: relative;
    }

    &-title {
      margin: 0 20px 0 0;
    }

    &-price {
      flex-shrink: 0;
      font-weight: 500;
      margin: 0 20px 0 auto;

      .time {
        color: $color-text-light
      }
    }

    .btn {
      border-radius: 3px;
      height: 40px;
      line-height: 36px;
      padding: 0 15px;
    }
  }

  & + .has-background,
  & + .block-text-on-bg {
    margin-top: -70px;
  }
}

.reservation-form {
  background: $color-light;
  border-radius: 4px;
  box-shadow: $shadow-bottom-wide;
  opacity: 0;
  padding: 24px;
  pointer-events: none;
  position: absolute;
  right: -167px;
  top: 100%;
  transition: $transition-basic;
  z-index: map-get($z-index, bottom );
  width: 290px;

  &.is-open {
    opacity: 1;
    pointer-events: auto;
    top: calc(100% - 10px);
  }
}

.summary-price {
  color: $color-text-light;
  text-align: right;

  .price {
    color: $color-heading;
    font-size: 21px;
    font-weight: 500;
  }
}

.expand {
  &-link {
    display: block;
    font-weight: 500;
    margin: 20px auto 0 auto;
    padding-right: 20px;
    position: relative;
    text-align: center;
    width: 135px;

    &:after {
      align-items: center;
      border: solid var(--color-primary);
      border-width: 0 0 2px 2px;
      content: "";
      display: flex;
      height: 8px;
      justify-content: center;
      position: absolute;
      right: 0;
      top: calc(50% - 3px);
      transition: $transition-basic;
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      width: 8px;
    }
  }

  &-content {
    margin-top: -10px;
    overflow: hidden;
    transition: $transition-basic;

    &.active + .expand-link:after {
      top: calc(50% + 1px);
      transform: translateX(-50%) translateY(-50%) rotate(135deg)
    }
  }

  &-content-inner {
    padding: 10px 0;

    .pricetable-row:first-of-type {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 1600px) {
  .reservation-form {
    right: 25px
  }
}

@media screen and (max-width: 1024px) {
  .block-pricetable {
    margin: 50px 0;
    
    .pricetable-price {
      flex-shrink: 0;
    }
  }
}

@media screen and (max-width: 720px) {
  .block-pricetable .pricetable {
    &-row {
      flex-wrap: wrap;
    }

    &-title {
      margin: 0 0 10px 0;
      width: 100%
    }

    &-price {
      margin: 0 20px 0 0;
    }

    .btn {
      margin-left: auto;
    }
  }
}

@media screen and (max-width: 560px) {
  .block-pricetable {
    .pricetable-select .edd-head {
      height: 60px;
      line-height: 58px;
      font-size: 16px;
    }

    .pricetable {
      &-title {
        text-align: center;
      }
  
      &-price {
        margin: 10px auto 0 auto;
      }
  
      .btn {
        margin: 10px 0 0 0;
        width: 100%
      }
    }
  }

  .reservation-form {
    width: calc(100% - 50px);
  }
}