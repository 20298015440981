.slider-gallery-small {
  border-bottom: 1px solid $color-border;
  margin: 70px auto;
  overflow: hidden;

  .section-title {
    margin-top: 0;
    margin-bottom: 20px;
  }
  
  .description.below-title {
    margin-top: 20px;
  }

  &-wrapper {
    position: relative;
  }

  &-inner {
    margin: 0 auto;
    max-width: 750px;
    position: relative;
  }

  &-list {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    margin: 30px 0 50px 0;
    padding: 0;
    transition: $transition-slider;
  }

  &-item {
    background: $color-bg-image;
    height: 270px;
    overflow: hidden;
    position: relative;
    width: 360px;

    &:before {
      display: none;
    }

    img {
      display: block;
      height: 100%;
      max-width: 100%;
      object-fit: cover;
      transition: $transition-basic;
      width: 100%;
    }

    .icon {
      display: block;
      height: 65px;
      left: 50%;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: $transition-basic;
      width: 65px;
    }

    &:hover {
      img {
        opacity: 0.65;
      }

      .icon {
        opacity: 1;
      }
    }
  }

  @for $i from 1 through 50 {
    .slider-gallery-small-list[data-count="#{$i}"] {
      width: calc(50% * #{$i});

      .slider-gallery-small-item {
        margin: 0 12px;
        width: calc((100% / #{$i}) - 25px);
      }
    }
  }

  .slider-nav {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 0 80px 0;

    .prev,
    .next {
      margin: 0 12px;

      &.is-hidden {
        display: none;
      }
    }
  }

  & + .has-background,
  & + .block-text-on-bg {
    margin-top: -70px;
  }
}

@media screen and (max-width: 1024px) {
  .slider-gallery-small {
    margin: 50px auto;

    .section-title {
      margin-top: 0;
    }

    .slider-nav {
      margin: 0 0 60px;
    }
  }
}

@media screen and (max-width: 720px) {
  .slider-gallery-small {
    &-item {
      height: 220px;
    }

    .slider-nav .prev {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 560px) {
  .slider-gallery-small {
    &-item {
      height: 280px;
    }

    @for $i from 1 through 50 {
      .slider-gallery-small-list[data-count="#{$i}"] {
        width: calc(100% * #{$i});
      }
    }
  }
}