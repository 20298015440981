.page-header {
  background: $color-light;
  height: 80px;
  left: 0;
  position: fixed;
  top: 0;
  transition: $transition-basic;
  width: 100%;
  z-index: map-get($z-index, navigation );

  .wrapper {
    align-items: center;
    display: flex;
    height: 100%;
  }

  .logo {
    display: block;
    flex-shrink: 0;
    height: 100%;
    padding: 5px;
    position: relative;
    transition: $transition-basic;
    width: 150px;

    img {
      display: block;
      height: 100%;
      margin: 0;
      object-fit: contain;
      object-position: left;
      width: 100%;
    }
  }
  
  .menu {
    &-list {
      align-items: center;
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &-item {
      margin: 0;

      a {
        color: $color-heading;
        display: inline-block;
        font-weight: 500;
        padding: 29px 23px;
        position: relative;
        text-decoration: none;
        text-align: center;

        &:after {
          background: var(--color-primary);
          bottom: 0;
          content: '';
          height: 4px;
          left: 50%;
          opacity: 0;
          position: absolute;
          transition: $transition-basic;
          transform: translateX(-50%);
          width: 0;
        }
      }

      &:before {
        display: none;
      }

      &:active,
      &:focus,
      &:hover {
        & > a {
          &:after {
            opacity: 1;
            width: 100%;
          }
        }
      }
    }

    & + .btn-wrapper {
      margin-left: auto;
    }
  }

  .link-dark {
    font-weight: 500;
    margin-left: auto;
    text-decoration: none;

    &.with-icon {
      .icon {
        margin-right: 10px;
      }
    }
  }
  
  .btn-wrapper {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: 50px;
    z-index: 1;
  }

  .btn {
    margin: 0 20px 0 30px;
    z-index: map-get($z-index, bottom );
  }
  
  .language-switcher {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 27px;
    list-style: none;
    margin: 0 0 0 10px;
    padding: 0 20px 0 0;
    position: relative;
    z-index: map-get($z-index, bottom );
    width: 54px;

    &:after {
      align-items: center;
      border: solid $color-dark;
      border-width: 0 0 2px 2px;
      content: "";
      display: flex;
      height: 7px;
      justify-content: center;
      pointer-events: none;
      position: absolute;
      right: 4px;
      top: 50%;
      transition: $transition-basic;
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      width: 7px;
    }

    &:active,
    &:focus,
    &:hover,
    &.current-menu-item {
      &:after {
        border: solid var(--color-primary);
        border-width: 0 0 2px 2px;
      }
    }

    .lang-item {
      align-items: center;
      color: $color-light;
      background: $color-light;
      display: none;
      font-size: 14px;
      height: 27px;
      justify-content: center;
      left: 0;
      margin: 0;
      overflow: hidden;
      padding: 3px 20px 3px 3px;
      position: absolute;
      text-transform: uppercase;
      top: 0;
      width: auto;

      &:before {
        display: none;
      }

      a {
        border: 1px solid $color-border;
        color: $color-light;
        display: block;
        height: 21px;
        width: 28px;

        img {
          display: block;
          height: auto;
          width: 100%;
        }
      }

      &.current-lang {
        display: block;
      }

      &.is-active {
        display: block;
      }
    }
  }

  .menu-toggle {
    background: var(--color-primary);
    border: 2px solid var(--color-primary);
    border-radius: 3px;
    cursor: pointer;
    display: none;
    height: 38px;
    margin-left: 10px;
    position: relative;
    width: 38px;
  
    .menu-toggle-bar {
      background: $color-light;
      display: block;
      height: 2px;
      left: 50%;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      transition: $transition-basic;
      width: 24px;

      &:before,
      &:after {
        background: $color-light;
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        transition: $transition-basic;
        width: 100%;
      }

      &:before {
        top: -8px
      }

      &:after {
        bottom: -8px;
      }
    }

    &:hover {
      background: $color-light;

      .menu-toggle-bar {
        background: var(--color-primary);

        &:before,
        &:after {
          background: var(--color-primary);
        }
      }
    }
  }

  & + .site-main {
    padding-top: 80px;

    & > *:first-child {
      margin-top: 0;
    }
  }

  &.is-fixed {
    box-shadow: $shadow-bottom;
    height: 80px;

    .menu-item a {
      padding: 31px 23px
    }
  }
}

.page-header.center {
  height: 140px;

  & + .site-main {
    padding-top: 140px;
  }

  .wrapper {
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    .top,
    .bottom {
      width: 100%;
    }

    .top {
      align-items: center;
      display: flex;
      height: 70px;
    }

    .bottom {
      transition: $transition-basic;
    }
  }

  .logo {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: center;
    left: 50%;
    order: 2;
    position: absolute;
    top: 5px;
    transform: translateX(-50%);
    width: 250px;

    img {
      height: 100%;
      object-position: center;
      transition: $transition-basic;
    }
  }

  .link-dark {
    margin: 0;
    order: 1;
    width: 225px;
  }

  .btn-wrapper {
    order: 2;
    margin-left: auto;
    z-index: 1;
  }

  .language-switcher {
    order: 2
  }

  .menu {
    margin: 0;
    width: 100%;

    &-list {
      justify-content: center;
    }

    &-item a {
      padding: 20px;
    }
  }

  &.is-fixed {
    height: 70px;

    .logo {
      img {
        height: 0;
        opacity: 0;
        pointer-events: none;
      }
    }

    .link-dark span {
      display: none;
    }

    .bottom {
      transform: translateY(-70px) translateX(30px);
      width: calc(100% - 280px);
    }

    .menu-list {
      height: 70px;
    }

    .menu-item a {
      line-height: 1.4;
      padding: 14px 15px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .page-header {
    .menu-item a {
      padding: 25px 15px;
    }

    .btn {
      margin: 0 15px;
      font-size: 14px;
      padding: 0 12px;
    }
  }

  .page-header.center {
    .logo {
      width: 240px
    }
  }
}

@media screen and (max-width: 1500px) {
  .page-header {
    .menu-item a {
      font-size: 15px;
      line-height: 1.4;
    }

    &.is-fixed {
      .link-dark {
        &.with-icon .icon {
          margin-right: 0;
        }
        
        span{
          display: none;
        }
      }

      .menu-item a {
        padding: 30px 15px;
      }
    }
  }

  .page-header.center {
    .logo {
      width: 220px
    }

    .menu-item a {
      padding: 20px 15px;
    }
  }
}

@media screen and (max-width: 1350px) {
  .page-header {
    height: 70px;

    & + .site-main {
      padding-top: 70px;
    }

    .btn-wrapper {
      margin-left: 10px
    }

    .btn {
      height: 44px;
      line-height: 42px;
      margin: 0 10px;
    }

    .menu {
      margin-left: 5px;
    }

    .menu-item a {
      font-size: 14px;
      padding: 25px 10px;
    }

    &.is-fixed {
      height: 70px;

      .menu-item a {
        padding: 25px 10px;
      }
    }
  }
  
  .page-header.center {  
    .menu-item a {
      font-size: 16px;
    }

    &.is-fixed {
      .menu-item a {
        font-size: 14px;
        padding: 25px 8px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .page-header {
    height: 60px;

    & + .site-main {
      padding-top: 60px;
    }

    .logo {
      width: 130px;
    }

    .btn {
      height: 40px;
      line-height: 38px;
      margin: 0
    }

    .menu-toggle {
      display: block;
    }

    .menu {
      background: $color-light;
      box-shadow: $shadow-bottom-menu;
      display: flex;
      flex-wrap: wrap;
      left: 0;
      margin: 0;
      opacity: 0;
      padding: 20px 0 100px 0;
      pointer-events: none;
      position: absolute;
      top: 55px;
      transition: $transition-basic;

      &-list {
        flex-wrap: wrap;
        width: 100%;
      }

      &-item {
        display: block;
        width: 100%;

        a {
          display: block;
          padding: 10px 20px;
          text-align: center;

          &:after {
            display: none;
          }
        }

        &:hover a {
          color: var(--color-primary)
        }
      }

      &.is-active {
        display: block;
      }

      &.is-showing {
        opacity: 1;
        pointer-events: auto;
        top: 50px;
        width: 100%;

        .menu-item a {
          padding: 10px 20px;
        }
      }
    }

    &.is-fixed {
      height: 60px;

      .menu.is-active,
      .menu.is-showing {
        .menu-list {
          margin: 0;
        }
      }
    }
  }

  .page-header.center {
    height: 70px;

    .menu {
      display: block;
    }
    
    .menu-list {
      height: auto;
    }
    
    .link-dark {
      width: 185px;
    }

    & + .site-main {
      padding-top: 70px;
    }

    &.is-fixed {
      .menu.is-showing {
        left: -20px;
        top: 0;
        width: calc(100% + 40px);
      }

      .bottom {
        transform: translateX(0) translateY(0px);
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .page-header.center {
    & + .site-main {
      padding-top: 60px;
    }

    .logo {
      top: 0;
    }
  }
  
  .page-header.center,
  .page-header.center.is-fixed {
    height: 60px;

    .wrapper .top {
      height: 60px;
      width: 100%;
    }

    .logo {
      align-items: center;
      display: flex;
      height: 60px;
      left: 0;
      justify-content: flex-start;
      opacity: 1;
      position: relative;
      pointer-events: auto;
      transform: none;
      width: 200px;

      img {
        object-position: left;
      }
    }

    .link-dark {
      margin-left: auto;
      order: 2;
      width: auto;

      .icon {
        margin-right: 0;
      }
    }

    .menu {
      margin: 0;

      &-item a {
        padding: 10px 20px;
      }
    }

    .btn-wrapper {
      margin-left: 10px;
      transform: none;
    }
  }

  .page-header.center.is-fixed {
    .logo img {
      height: 100%;
      opacity: 1;
      pointer-events: auto;
    }
  }
}

@media screen and (max-width: 720px) {
  .page-header .link-dark,
  .page-header.center .link-dark {
    &.with-icon .icon {
      margin-right: 0;
    }

    span {
      display: none;
    }
  }
}

@media screen and (max-width: 560px) {
  .page-header {
    height: 90px;

    .wrapper {
      padding-top: 30px
    }

    &.no-phone {
      padding-top: 0;
    }

    & + .site-main {
      padding-top: 90px;
    }

    .btn {
      border-radius: 0;
      font-size: 14px;
      height: 30px;
      line-height: 28px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .menu {
      top: 80px;

      &.is-showing {
        height: calc(100vh - 87px);
        overflow: scroll;
        top: 87px
      }
    }
    
    &.center {
      & + .site-main {
        padding-top: 90px;
      }
    }

    &.center,
    &.center.is-fixed {
      height: 90px;

      .logo {
        width: 180px;
      }

      .wrapper {
        position: unset;
      }

      .btn-wrapper {
        margin-left: 0;
      }
    }

    &.center.is-fixed {
      .menu.is-showing {
        top: 0
      }
    }

    &.is-fixed {
      min-height: 90px;
      height: auto;

      .logo {
        width: 180px;
      }
    }
  }
}
