.block-text-on-bg {
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  margin: 70px 0;
  min-height: 520px;
  position: relative;
  width: 100%;

  &:before {
    background-color: $color-bg-image;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.35;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: map-get($z-index, base )
  }

  .text-wrapper {
    color: $color-light;
    padding: 20px;
    text-align: center;
    text-shadow: 0 3px 6px rgba($color-dark, .16);
    z-index: map-get($z-index, bottom );
  }

  .page-title {
    color: $color-light
  }

  &.short {
    margin: 50px 0;
    min-height: 390px;

    .page-title {
      font-size: 46px;
    }

    p {
      font-size: 21px;
      font-weight: 500;
    }
  }

  &.lower {
    min-height: 300px;
  }

  &.dark {
    .text-wrapper,
    .page-title {
      color: $color-heading
    }
  }
}

body.without-menu .site-main > *:first-child {
  margin-top: 0;
}

@media screen and (max-width: 1024px) {
  .block-text-on-bg {
    background-attachment: inherit;
    margin: 50px 0;
    min-height: 420px;

    &.short {
      margin: 30px 0;
      min-height: 320px;
  
      .page-title {
        font-size: 36px;
      }
  
      p {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .block-text-on-bg {
    &.short {
      margin: 30px 0;
      min-height: 320px;
  
      .page-title {
        font-size: 30px;
      }
  
      p {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .block-text-on-bg {
    margin: 30px 0;

    & + .block-with-page-title {
      margin-top: -30px;
    }
  }
}