.blockquote {
  border-top: 1px solid $color-border;
  margin: 0;

  &-inner {
    text-align: center;
    padding-top: 70px;

    .icon {
      display: block;
      fill: var(--color-primary);
      height: 36px;
      margin: 0 auto;
      width: 46px;
    }

    p {
      margin: 35px 80px 25px 80px;
      padding-bottom: 35px;
      position: relative;

      &:after {
        background: $color-border;
        bottom: 0;
        content: '';
        height: 2px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 180px;
      }
    }

    cite {
      color: $color-text-light;
      font-style: normal;
    }

    .blocquote-name {
      color: $color-heading;
      display: block;
      font-size: $font-subtitle-size;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 1024px) {
  .blockquote {
    &-inner {
      padding-top: 50px;
    }
  }
}

@media screen and (max-width: 840px) {
  .blockquote-inner p {
    margin: 30px auto 40px auto;
    max-width: 600px;
    padding-bottom: 35px;
  }
}

@media screen and (max-width: 560px) {
  .blockquote-inner {
    padding-top: 30px;

    .icon {
      height: 30px;
      width: 35px;
    }
    
    p {
      margin: 20px auto 20px;
      padding-bottom: 25px;
    }

    cite {
      font-size: 14px;
    }

    .blocquote-name {
      font-size: 18px;
    }
  }
}