.btn,
button {
    appearance: none;
    border-radius: 0;
    outline: none;
}

.btn {
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 5px;
  color: $color-light;
  cursor: pointer;
  display: inline-block;
  font-family: $font-text;
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  height: 50px;
  padding: 0 20px;
  text-align: center;
  text-decoration: none;
  transition: $transition-basic;

  &:active,
  &:focus,
  &:hover {
    background: $color-light;
    border: 1px solid $color-heading;
    color: $color-heading;
  }

  &--light {
    background: $color-light;
    border: 1px solid $color-light;
    color: $color-heading;

    &:active,
    &:focus,
    &:hover {
      background: var(--color-primary);
      border: 1px solid var(--color-primary);
      color: $color-light;
    }
  }

  &--border {
    background: $color-light;
    border: 2px solid var(--color-primary);
    color: var(--color-primary);

    &:active,
    &:focus,
    &:hover {
      background: var(--color-primary);
      border: 2px solid var(--color-primary);
      color: $color-light;
    }
  }
}

@media screen and (max-width: 560px) {
  .btn {
    font-size: 14px;
    line-height: 38px;
    height: 40px;
    padding: 0 15px;
  }
}