#bookero-plugin {
  --bookero-plugin-color-link: #a00;
  --bookero-plugin-color-primary: #f00;
  --bookero-plugin-color-primary-dark: #c00; /* darken 10% */
  --bookero-plugin-color-primary-darker: #b30000; /* darken 15% */
  --bookero-plugin-color-primary-light: #ff9999; /* lighten 30% */
  --bookero-plugin-color-primary-lighter: #ffe6e6; /* lighten 45% */
  --bookero-plugin-color-primary-lightest: rgba(255, 0, 0, .1);
}

#bookero-plugin .bookero-plugin-form .field .multiselect__option--highlight,
#bookero-plugin .bookero-plugin-error-btn,
#bookero-plugin .bookero-plugin-header,
#bookero-plugin .switcher.is-active,
#bookero-plugin .add-to-cart-section .add-button,
#bookero-plugin .hours-section .hours-wrapper .hours-list-item.is-in-cart.is-selected,
#bookero-plugin .hours-section .hours-wrapper .hours-list-item.is-selected,
#bookero-plugin .result-popup-content-payment-link a,
#bookero-plugin .payment-section.payment-methods-item.is-active,
#bookero-plugin .calendar-days-list-cell.is-in-cart.is-selected,
#bookero-plugin .calendar-days-list-cell.is-valid.is-selected,
#bookero-plugin .week-days-hour.is-in-cart.is-selected,
#bookero-plugin .week-days-hour.is-selected,
#bookero-plugin .dates-section .vdp-datepicker__calendar .cell.selected,
#bookero-plugin .submit-section .submit-button,
body #bookero-plugin[data-mode="sticky"] .bookero-sticky-plugin-toggle {
  background: var(--bookero-plugin-color-primary);
}

#bookero-plugin .calendar-days-list-cell.is-sub-selected{
  background: var(--bookero-plugin-color-primary-light)!important;
  border-color: var(--bookero-plugin-color-primary-light)!important;
  color: #fff!important;
}
#bookero-plugin .calendar-days-list-cell.is-sub-selected.is-valid{
  color: #fff!important;
}
#bookero-plugin .calendar-days-list-cell.is-sub-selected:before{
  background: var(--bookero-plugin-color-primary-light);
}
#bookero-plugin .calendar-days-list-cell.is-selected+.is-sub-selected:before {
  background: -webkit-gradient(linear, left top, right top, from(var(--bookero-plugin-color-primary)), to(var(--bookero-plugin-color-primary-light)));
  background: -o-linear-gradient(left, var(--bookero-plugin-color-primary), var(--bookero-plugin-color-primary-light));
  background: linear-gradient(to right, var(--bookero-plugin-color-primary), var(--bookero-plugin-color-primary-light));
}

#bookero-plugin .inquiries-list-add-icon:before,
#bookero-plugin .inquiries-list-add-icon:after{
  background: var(--bookero-plugin-color-link);
  -webkit-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

#bookero-plugin .bookero-plugin-form-message[data-type="info"],
#bookero-plugin .people-section .people-number-wrapper .people-number-plus:active,
#bookero-plugin .people-section .people-number-wrapper .people-number-minus:active,
#bookero-plugin .people-section .people-number-wrapper .people-number-plus:focus,
#bookero-plugin .people-section .people-number-wrapper .people-number-minus:focus,
#bookero-plugin .people-section .people-number-wrapper .people-number-plus:hover,
#bookero-plugin .people-section .people-number-wrapper .people-number-minus:hover,
#bookero-plugin .submit-section .back-button:active,
#bookero-plugin .submit-section .back-button:hover,
#bookero-plugin .submit-section .back-button:hover,
#bookero-plugin .inquiry-connected-termins,
#bookero-plugin .inquiries-list-add,
#bookero-plugin .inquiries-list-add:active,
#bookero-plugin .inquiries-list-add:focus,
#bookero-plugin .inquiries-list-add:hover,
#bookero-plugin .hours-section.hours-wrapper.hours-list-item.is-in-cart:not(.is-selected),
#bookero-plugin .hours-section.hours-wrapper.hours-list-item:not(.is-selected),
#bookero-plugin .calendar-days-list-cell.is-in-cart,
#bookero-plugin .calendar-days-list-cell.is-selected.auto-select-in-progress,
#bookero-plugin .calendar .waiting-list-notification > strong,
#bookero-plugin .calendar-days-list-cell.is-valid:not(.is-selected),
#bookero-plugin .week .waiting-list-notification > strong,
#bookero-plugin .week-days-hour.is-in-cart:not(.is-selected),
#bookero-plugin .week-days-hour.is-valid:not(.is-selected),
#bookero-plugin .week-days-hour:not(.is-selected),
#bookero-plugin .bookero-plugin-form-wrapper a:hover,
#bookero-plugin .dates-section .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
#bookero-plugin .dates-section .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
#bookero-plugin .dates-section .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover,
#bookero-plugin .hours-section .hours-wrapper .hours-list-item:not(.is-selected) {
  color: var(--bookero-plugin-color-primary);
}

#bookero-plugin .bookero-plugin-form-wrapper a {
  color: var(--bookero-plugin-color-link);
}

#bookero-plugin .bookero-plugin-form-wrapper a:active,
#bookero-plugin .bookero-plugin-form-wrapper a:focus {
  color: var(--bookero-plugin-color-primary-darker);
}

#bookero-plugin .bookero-plugin-form-wrapper .result-popup-content-paylink,
#bookero-plugin .bookero-plugin-form-wrapper .result-popup-content-paylink:active,
#bookero-plugin .bookero-plugin-form-wrapper .result-popup-content-paylink:focus,
#bookero-plugin .bookero-plugin-form-wrapper .result-popup-content-paylink:hover,
#bookero-plugin .bookero-plugin-form-wrapper .inquiries-list-add:hover {
  color: white;
}

#bookero-plugin .bookero-plugin-form-wrapper .inquiries-list-add:hover .inquiries-list-add-icon:before,
#bookero-plugin .bookero-plugin-form-wrapper .inquiries-list-add:hover .inquiries-list-add-icon:after{
  background: white;
}

#bookero-plugin .bookero-plugin-form .field input:active,
#bookero-plugin .bookero-plugin-form .field textarea:active,
#bookero-plugin .bookero-plugin-form .field select:active,
#bookero-plugin .bookero-plugin-form .field input:focus,
#bookero-plugin .bookero-plugin-form .field textarea:focus,
#bookero-plugin .bookero-plugin-form .field select:focus,
#bookero-plugin .temporary-price-section:before,
#bookero-plugin .payment-summary-section.is-loading:before,
#bookero-plugin .switcher.is-active,
#bookero-plugin .submit-section .submit-button,
#bookero-plugin .hours-section.hours-wrapper.is-loading:before,
#bookero-plugin .hours-section.hours-wrapper.hours-list-item.is-in-cart.is-selected,
#bookero-plugin .hours-section.hours-wrapper.hours-list-item.is-in-cart,
#bookero-plugin .hours-section.hours-wrapper.hours-list-item.is-selected,
#bookero-plugin .result-popup-icon-loader,
#bookero-plugin .payment-section.payment-methods-item:hover,
#bookero-plugin .payment-section.payment-methods-item.is-active,
#bookero-plugin .calendar-days-list-cell.is-in-cart.is-selected,
#bookero-plugin .calendar-days-list-cell.is-in-cart,
#bookero-plugin .calendar-days-list-cell.is-valid.is-selected,
#bookero-plugin .week-days-hour.is-in-cart.is-selected,
#bookero-plugin .week-days-hour.is-in-cart:not(.is-selected),
#bookero-plugin .week-days-hour.is-selected,
#bookero-plugin .dates-section .is-term.is-loading:before,
#bookero-plugin .dates-section .week-wrapper.is-loading:before,
#bookero-plugin .hours-section .hours-wrapper.is-loading:before,
#bookero-plugin .dates-section .calendar-wrapper.is-loading:before {
  border-color: var(--bookero-plugin-color-primary);
}

#bookero-plugin .bookero-plugin-error-btn:active,
#bookero-plugin .bookero-plugin-error-btn:focus,
#bookero-plugin .bookero-plugin-error-btn:hover,
#bookero-plugin .submit-section .submit-button:active,
#bookero-plugin .submit-section .submit-button:focus,
#bookero-plugin .submit-section .submit-button:hover,
#bookero-plugin .add-to-cart-section .add-button:active,
#bookero-plugin .add-to-cart-section .add-button:focus,
#bookero-plugin .add-to-cart-section .add-button:hover,
#bookero-plugin .result-popup-content-payment-link a:active,
#bookero-plugin .result-popup-content-payment-link a:focus,
#bookero-plugin .result-popup-content-payment-link a:hover {
  background: var(--bookero-plugin-color-primary-dark);
}

#bookero-plugin .workers-section .worker-info,
#bookero-plugin .bookero-plugin-form-message[data-type=info] {
    background: var(--bookero-plugin-color-primary-lightest);
}

#bookero-plugin .bookero-plugin-form-message[data-type="info"] {
    border-color: var(--bookero-plugin-color-primary-lighter);
}

#bookero-plugin .customer-section .field-phone .vti__dropdown:active,
#bookero-plugin .customer-section .field-phone .vti__dropdown:focus {
  outline-color: var(--bookero-plugin-color-primary)!important;
}

#bookero-plugin .inquiry-actions-details:after {
  border-top-color: var(--bookero-plugin-color-link);
  border-right-color: var(--bookero-plugin-color-link);
}

#bookero-plugin .inquiry-actions-details:hover:after {
  border-top-color: var(--bookero-plugin-color-primary-darker);
  border-right-color: var(--bookero-plugin-color-primary-darker);
}

#bookero-plugin .inquiries-list-add:active,
#bookero-plugin .inquiries-list-add:focus,
#bookero-plugin .inquiries-list-add:hover {
  background: var(--bookero-plugin-color-primary-lighter);
}

#bookero-plugin .hours-section-load-error-reload-link,
#bookero-plugin .dates-section-load-error-reload-link {
  color: var(--bookero-plugin-color-link);
}

#bookero-plugin .hours-section .hours-wrapper .hours-list-item,
#bookero-plugin .calendar-days-list-cell.is-valid,
#bookero-plugin .week-days-hour.is-valid:not(.is-selected),
#bookero-plugin .week-days-hour:not(.is-selected),
#bookero-plugin .dates-section .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
#bookero-plugin .dates-section .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
#bookero-plugin .dates-section .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  background: var(--bookero-plugin-color-primary-lightest);
  border-color: var(--bookero-plugin-color-primary-lightest);
}

#bookero-plugin .inquiries-list-add {
  background: var(--bookero-plugin-color-primary-lightest);
  border-color: var(--bookero-plugin-color-primary);
}

#bookero-plugin .inquiries-list-add:active,
#bookero-plugin .inquiries-list-add:focus,
#bookero-plugin .inquiries-list-add:hover {
  background: var(--bookero-plugin-color-primary);
  color: #fff;
}

#bookero-plugin .payment-section .payment-methods-item:hover{
  border-color: var(--bookero-plugin-color-primary);
}

#bookero-plugin .payment-section .payment-methods-item.is-active {
  background: var(--bookero-plugin-color-primary);
  border-color: var(--bookero-plugin-color-primary);
}

#bookero-plugin .invoice-section .button-group .button-group-button {
  border-color: var(--bookero-plugin-color-primary);
}

#bookero-plugin .invoice-section .button-group .button-group-button.is-active {
  background-color: var(--bookero-plugin-color-primary);
}