input[type="submit"] {
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 5px;
  color: $color-light;
  cursor: pointer;
  display: inline-block;
  font-family: $font-text;
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  margin-top: 8px;
  height: 50px;
  padding: 0 20px;
  text-align: center;
  text-decoration: none;
  transition: $transition-basic;
  width: 100%;

  &:active,
  &:focus,
  &:hover {
    background: $color-light;
    color: var(--color-primary);
  }
}

.custom-radio {
  align-items: center;
  color: $color-heading;
  cursor: pointer;
  display: flex;
  font-family: $font-text;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 10px 0;
  padding-left: 30px;
  position: relative;

  input[type="radio"] {
    height: 20px;
    opacity: 0;
    padding: 0;
    width: 20px;
        
    &:checked + .custom-radio__label {
      &:before {
        background: var(--color-primary);
        border-radius: 50%;
        content: '';
        height: 8px;
        left: 6px;
        position: absolute;
        top: 9px;
        width: 8px;
      }

      &:after {
        border: 2px solid var(--color-primary);
        border-radius: 50%;
        content: "";
        height: 20px;
        left: 0;
        position: absolute;
        top: 3px;
        width: 20px;
      }
    }

    &:disabled + .custom-radio__label {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &:before {
    background: $color-light;
    border: 1px solid $color-border-dark;
    border-radius: 50%;
    content: "";
    height: 20px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 20px;
  }
}

.custom-select__label {
  margin-top: 20px;

  label {
    color: $color-heading;
    font-weight: 500;
  }
}
